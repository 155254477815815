@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
}

img,
svg,
canvas,
iframe,
video {
  max-width: 100%;
}

a,
span {
  display: inline-flexbox;
}

#root .logo img {
  background-color: #000e50;
  padding: 20px 10px;
  border-radius: 10px;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  height: 200px;
}
#root .logo h1 {
  font-size: 44px;
  margin: 20px 0;
}
@media (max-width: 499px) {
  #root .logo h1 {
    font-size: 30px;
  }
}
#root .version {
  margin-bottom: 30px;
  font-style: italic;
}
#root .box:not(:last-child) {
  margin-bottom: 10px;
}
#root .box .title {
  line-height: 1.4;
  font-size: 24px;
  margin-bottom: 10px;
}
#root .box .content {
  font-size: 20px;
  position: relative;
  line-height: 28px;
}
#root .box .content:not(:last-child) {
  margin-bottom: 10px;
}
#root .box .table_content {
  margin-top: 30px;
}
#root .box .d-flex {
  display: flex;
}
@media (max-width: 767px) {
  #root .box .d-flex {
    display: block;
  }
}
#root .box .mt-4 {
  margin-top: 20px;
}
#root .box .mt-2 {
  margin-top: 10px;
}
#root .box .mr-4 {
  margin-right: 20px;
}
#root .box .right_img {
  padding-left: 20px;
  margin-left: auto;
  width: 360px;
  flex-shrink: 0;
}
@media (max-width: 767px) {
  #root .box .right_img {
    padding: 20px 0 0;
    margin-left: 0;
  }
}
#root .box ul {
  padding-left: 20px;
}
#root .box ul li {
  font-size: 18px;
}
#root .box ul li:not(:last-child) {
  margin-bottom: 10px;
}
#root .box .sideby_content {
  display: flex;
  justify-content: space-between;
}
#root .box .earning-content {
  margin-top: 30px;
}
#root .box .responsive-table {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  width: 100%;
  margin-right: 20px;
}
#root .box .responsive-table .table {
  vertical-align: middle;
  width: 100%;
  white-space: nowrap;
  border-collapse: collapse;
}
#root .box .responsive-table .table thead tr th {
  font-family: inherit;
  padding: 6px 20px;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
}
#root .box .responsive-table .table tbody tr td {
  font-size: 18px;
  font-family: inherit;
  padding: 6px 20px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}
#root .box .sub_title {
  font-size: 20px;
}
#root .box .sideby_images {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  justify-content: space-between;
}
@media (max-width: 767px) {
  #root .box .sideby_images {
    flex-direction: column;
    justify-content: center;
  }
  #root .box .sideby_images > img:not(:last-child) {
    margin-bottom: 10px;
  }
}
#root .box .note {
  font-size: 16px;
  font-weight: 500;
}
#root .box .note span {
  color: rgb(176, 142, 19);
  font-weight: 600;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 15px;
}/*# sourceMappingURL=help.css.map */

@media (max-width: 767px) {
  .container {
    text-align: center;
  }
}