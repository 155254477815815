body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #000; /* Dark background */
  color: #fff; /* Light text */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header,
.App-footer {
  padding: 20px;
  background-color: #333; /* Darker background for header and footer */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.App-header {
  margin-bottom: 40px;
}
.App-footer{
  margin-top: 40px;
}

.App-header-left {
  display: flex;
  align-items: center;
}

.App-heading {
  margin: 0;
  font-size: 24px;
}

.App-nav {
  display: flex;
  gap: 40px;
}

.App-nav a {
  color: #fff;
  text-decoration: none;
}

.App-nav a:hover {
  text-decoration: underline;
}


.App-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.App-content {
  background-color: #fff; /* Light background for the container */
  color: #000; /* Dark text for the container */
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  text-align: center;height: 400px;
}

.App-footer span {
  color: #fff;
}
